import React from 'react';
import axios from 'axios';
import GeoSuggest from 'react-geosuggest';
import { generateAPIUrl as getAPIUrl } from 'aqt-base-components';

/**
 * Module permettant de checker si une adresse peut être livré par un point de vente
 */
class ModulePointOfSale extends React.Component {
    customStyle = {
        input : {
            position   : 'relative',
            background : '#fff',
            border     : '1px solid #9B9B9B',
            height     : '38px',
            width      : '100%',
            padding    : '0 8px'
        },
        suggests : {
            listStyle               : 'none',
            border                  : '1px solid #9B9B9B',
            borderTop               : 'none',
            borderBottomLeftRadius  : '3px',
            borderBottomRightRadius : '3px'
        },
        suggestItem : {
            padding : '5px 0px 5px 10px',
            cursor  : 'pointer'
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            api_key_google_map : null,
            google_map_loaded  : false,
            hasSelectedAddress : false
        };
    }
    componentDidMount = async () => {
        try {
            const modulePOSData = await axios.post(`${getAPIUrl()}v2/module`, { PostBody: { filter: { name: 'pointofsale-aquila' } } });
            const { data: modulePOS } = modulePOSData;
            if (modulePOS && modulePOS.config && modulePOS.config.api_key_google_map) {
                const srcGoogleMap = `https://maps.googleapis.com/maps/api/js?key=${modulePOS.config.api_key_google_map}&libraries=places`;
                this.loadScript(srcGoogleMap);
                this.setState({ api_key_google_map: modulePOS.config.api_key_google_map });
            }
        } catch (error) {
            console.error('Pointofsale : ', error);
        }
    }
    convertGeoSuggest = (suggest) => {
        const delivery = {
            street_number : '',
            route         : '',
            locality      : '',
            postal_code   : '',
            country       : '',
        };
        for (let i = 0; i < suggest.gmaps.address_components.length; i++) {
            const valueType = suggest.gmaps.address_components[i].types[0];
            const valueLong = suggest.gmaps.address_components[i].long_name;
            const valueShort = suggest.gmaps.address_components[i].short_name;
            delivery[valueType] = valueLong;
            if (valueType === 'country') {
                delivery.isoCountryCode = valueShort;
            }
        }
        return {
            line1          : delivery.street_number + (delivery.route ? ` ${delivery.route}` : ''),
            zipcode        : delivery.postal_code,
            city           : delivery.locality,
            isoCountryCode : delivery.isoCountryCode,
            country        : delivery.country,
        };
    }
    onSuggestSelect = async (suggest) => {
        const delivery_address = this.convertGeoSuggest(suggest);
        // On vérifie si un point de vente peut livrer cette adresse
        try {
            const result = await axios.post(`${getAPIUrl()}/pointsOfSale/findForDelivery`, delivery_address);
            if (result.data.code === 'CAN_BE_DELIVERED') {
                this.setState({
                    delivery_address   : Object.assign({}, this.state.delivery_address, delivery_address),
                    hasSelectedAddress : true,
                });
            } else {
                showSnackBar('Cette adresse ne peut pas être livrée.', 'ERROR');
            }
        } catch (error) {
            console.error('Pointofsale : ', error);
        }
    }
    loadScript = (srcLink) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = srcLink;
        const el = document.getElementsByTagName('script')[0];
        el.parentNode.insertBefore(script, el);
        script.addEventListener('load', () => {
            this.setState({ google_map_loaded: true });
        });
    }
    render() {
        if (!process.browser || !this.state.api_key_google_map || !this.state.google_map_loaded) return '';
        const { GeoSuggestAttr } = this.props;
        return (
            <div>
                <GeoSuggest
                    name="address"
                    id="address"
                    required=""
                    minLength={5}
                    country="fr"
                    location={new google.maps.LatLng(43.919359, 6.6167766)}
                    radius={500}
                    onSuggestSelect={this.onSuggestSelect}
                    types={['geocode']}
                    ignoreTab
                    style={this.customStyle}
                    {...GeoSuggestAttr}
                />
                <style jsx global>{`
                    li.geosuggest__item:hover{
                        background-color: #e6e6e6;
                    }
                    .geosuggest__suggests--hidden {
                        max-height: 0;
                        overflow: hidden;
                        border: 0px !important;
                      }
                    .geosuggest__item--active {
                        background: #267dc0;
                        color: #fff;
                    }
                `}</style>
            </div>
        );
    }
}
export default ModulePointOfSale;

